import { render, staticRenderFns } from "./AllNotifications.html?vue&type=template&id=583915aa&scoped=true&"
import script from "./AllNotifications.vue?vue&type=script&lang=js&"
export * from "./AllNotifications.vue?vue&type=script&lang=js&"
import style0 from "./AllNotifications.scss?vue&type=style&index=0&id=583915aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583915aa",
  null
  
)

export default component.exports