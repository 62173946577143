<template src="./AllNotifications.html"></template>

<script>
import NotificationCard from "@/components/NotificationCard/NotificationCard";
import notificationsService from "../../services/Notifications";
import { districtInfo } from "@/utils/constants";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "all-notifications",
  components: {
    NotificationCard,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      typeNotifications: [],
      notifications: {},
      activeTab: null,
      academico:"/academico"
    };
  },
  created() {
    this.getTypeNotification();
  },
  methods: {
    async getTypeNotification() {
      this.typeNotifications =
        await notificationsService.getTypeNotifications();
      this.activeTab = 0;
      await this.getNotification();
    },
    async getNotification() {
      this.notifications = await notificationsService.getNotifications();
    },
    getDistrictInfo(district) {
      const item = districtInfo.find((item) => item.name == district);
      return item;
    },
  },
  computed: {
    getNotificationByType() {
      return !this.typeNotifications
        ? []
        : this.notifications[this.typeNotifications[this.activeTab].name];
    },
  },
};
</script>

<style lang="scss" scoped src="./AllNotifications.scss"></style>
